import _ from 'lodash';
import { mapGetters, mapActions } from "vuex";
import Type from "@/components/inventory/select-exhibition-type"
import Country from "@/components/inventory/select-country"
import City from "@/components/inventory/select-city"
import Products from "@/components/multiSelects/multi-select-products"
import selectNotificationTypes from '@/components/inventory/select-notification-types';

// Drawer components
import CreateCostTransaction from "@/views/costTransactions/components/crm-create";
import UpdateCostTransaction from "@/views/costTransactions/components/crm-update";

export default {
    components: {
        Type,
        Country,
        City,
        CreateCostTransaction,
        UpdateCostTransaction,
        Products,
		selectNotificationTypes
    },
    data() {
        return {
          usersList: [],
          productList: [],
          selectedUsersList: [],
          selectedUsersContainer: false,
          drawerCreateCostTransaction: false,
          drawerUpdateCostTransaction: false,
          selectedCostTransaction: {},
          newCostIndex: null,
		  minutes: [
				{
					value: 5,
					label: '5'
				}, 
				{
					value: 10,
					label: '10'
				},
				{
					value: 15,
					label: '15'
				},
				{
					value: 20,
					label: '20'
				},
				{
					value: 30,
					label: '30'
				},
				{
					value: 45,
					label: '45'
				},
			],
        };
    },
    computed: {
        ...mapGetters({
            rules: "exhibitions/rules",
            columns: "exhibitions/columns",
            getUserSearch: "users/search",
            getInventoryUsers: "users/inventory",
            authUser: "auth/user",
            costTransactions: "costTransactions/list",
            products: "products/inventory",
            costTypes: "costTypes/inventory",
            bankAccounts: "bankAccounts/inventory",
            paymentTypes: "paymentTypes/inventory",
        }),
        updateProductList(){
            if(this.form.products){
                return this.products.filter(el => this.form.products.includes(el.id));
            }
            return [];
        }
    },
	watch: {
		'form.notify': {
			handler: function(newVal){
				if (!newVal){
					this.form.notification_type_id = null;
					this.form.notification_time = '';
				}
			},
			deep: true
		}
	},
    created() {
        if (this.model != null) {           
          this.form = JSON.parse(JSON.stringify(this.model));
          if(!this.form.user_id){
            this.form.user_id = this.authUser.id;    
          }   
        }
    },
    methods: {
        ...mapActions({
          showInventoryUsers: "users/inventory",
          search: "users/search",
          updateCostTransactions: "costTransactions/index",
          deleteCostTransaction: "costTransactions/destroy",
        }),
        // Users Section
        selectUsers(user) {
          let $el = event.target.closest(".users-dropdown-items");
          if (!this.selectedUsersList.some((person) => person.id === user.id)) {
            this.selectedUsersList.push(user);
            $el.classList.add("active");
          } else {
            this.selectedUsersList = this.selectedUsersList.filter(
              (item) => item.id !== user.id
            );
            $el.classList.remove("active");
          }
        },
        findResponsibleUsers() {
          const { usersDropdown } = this.$refs;
          let value = event.target.value;
          if (value) {
            this.searchUsers(value, this);
          }
          if (value) {
            usersDropdown.classList.add("active");
          } else {
            usersDropdown.classList.remove("active");
          }
        },
        setUsers() {
          this.form.participants = this.selectedUsersList.map(item => item.id);
          const { usersDropdown, responsibleUsersInput } = this.$refs;
  
          this.selectedUsersContainer = true;
          usersDropdown.classList.remove("active");
          responsibleUsersInput.value = "";
        },
        searchUsers: _.debounce((search, self) => {
          if (_.isFunction(self.search)) {
            self
              .search(search)
              .then((res) => {
                self.usersList = JSON.parse(JSON.stringify(self.getUserSearch.filter(item => item.id !== self.form.user_id)));
              })
              .catch((err) => {});
          }
        }, 1000),
        getSelectedUsers() {
          const { usersDropdown } = this.$refs;
          usersDropdown.classList.toggle("active");
          if (usersDropdown.classList.contains("active")) {
            this.showInventoryUsers().then(() => {
              this.usersList = JSON.parse(JSON.stringify(this.getInventoryUsers.filter(item => item.id !== (this.authUser ? this.authUser.id : null))));
            });
          }
        },
        removeFromSelectedUsers(user) {
          this.selectUsers(user);
          this.form.participants = this.selectedUsersList.map(item => item.id);
        },
  
        // PRODUCTS Section
        removeProductFromList(id){
          this.form.products.splice(this.form.products.indexOf(id), 1)
        },
        // Cost Transactions Section
        newCost(data){
          this.form.costTransactions.push(this.costRefresh(data));
        },
        costRefresh(data){
          let costType = this.costTypes.find(el => el.id === data.cost_type_id)
          if(costType){
            this.$set(data, 'costType', costType);
          }
          let bankAccount = this.bankAccounts.find(el => el.id === data.bank_account_id)
          if(bankAccount){
            this.$set(data, 'bankAccount', bankAccount);
          }
          let paymentType = this.paymentTypes.find(el => el.id === data.payment_type_id)
          if(paymentType){
            this.$set(data, 'paymentType', paymentType);
          }
          return data;
        },
        newCostUpdate(data){
          this.$set(this.form.costTransactions, this.newCostIndex, this.costRefresh(data));
        },
        updateCost(row, index){
          this.selectedCostTransaction = row;
          this.drawerUpdateCostTransaction = true;
          if(!row.id) this.newCostIndex = index;
        },
        deleteCost(row, index){
          this.$confirm(
            this.$t('message.do_you_really_want_to_do_this'),
            this.$t('message.warning'),
            {
              confirmButtonText: this.$t('message.yes'),
              cancelButtonText: this.$t('message.no'),
              type: "warning",
            }
          )
            .then(() => {
                if(row.id){
                    this.deleteCostTransaction(row.id)
                        .then(res => {
                            this.$alert(res);
                        })
                        .catch(err => {
                            this.$alert(err);
                        })
                }
                this.form.costTransactions.splice(index, 1);
            })
            .catch(() => {
            });
        },
  
        // Submit Form
        submit(close = true) {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.form.begin_date = this.form.date[0];
                    this.form.end_date = this.form.date[1];
                    this.form.costTransactions = this.form.costTransactions.map(el => {
                      return {
                          id: el.id, 
                          cost_type_id: el.cost_type_id,
                          payment_type_id: el.payment_type_id,
                          bank_account_id: el.bank_account_id,
                          money_amount: el.money_amount,
                          currency_rate: el.currency_rate,
                          date: el.date,
                          comment: el.comment,
                          created_at: el.created_at,
                          updated_at: el.updated_at,
                        }
                    })
                    this.loadingButton = true;
                    this.save(this.form)
                        .then((res) => {
                            this.loadingButton = false;
                            this.$alert(res);
                            this.parent().listChanged();
                            if (close) this.close();
                        })
                        .catch((err) => {
                            this.loadingButton = false;
                            this.$alert(err);
                        });
                }
            });
        },
    },
}